// 
// user.js
// Use this to write your custom JS
//

//get current year
const yearElements = document.querySelectorAll('.currentYear');
for (let e of yearElements) {
    e.innerHTML = new Date().getFullYear();
}

//On form submit
window.onLoading = function(form){
    //Disable all buttons on form
    const buttons = form.querySelectorAll('button');
    buttons.forEach(button => {
        button.disabled = true;
    })

    //Remove any alerts
    if (form.querySelector('.alert')) { form.querySelector('.alert').remove(); }

    //Display loading spinnr on submit button
    const spinner = document.createElement('span');
    spinner.classList.add("spinner-border", "spinner-border-sm")
    form.querySelector("[type='submit']").prepend(spinner);
}

//On form complete
window.loadingComplete = function(form){
    //Enable all buttons on form
    const buttons = form.querySelectorAll('button');
    buttons.forEach(button => {
        button.disabled = false;
    })

    //Remove loading spinnr on submit button
    if (form.querySelector('.spinner-border')) { form.querySelector('.spinner-border').remove() }
}


const emailBtns = document.querySelectorAll("a.send-email");
const emailModal = document.querySelector("#modalEmail");
const emailForm = document.querySelector('#emailForm');
for (let btn of emailBtns) {
    btn.addEventListener('click', e => {
        const contact = btn.closest('div');
        const contactName = contact.querySelector('.emailName').innerText.trim();
        const contactTitle = contact.querySelector('.emailTitle').innerText.trim();
        const recipientId = btn.getAttribute('data-recipientId');

        emailModal.querySelector('#emailTo').innerText = contactName;
        emailModal.querySelector('#emailTitle').innerText = contactTitle;
        emailForm.elements.recipientId.value = recipientId;
    })
}

emailForm.addEventListener('submit', e => {
    e.preventDefault();
    onLoading(emailForm);

    //console.log(emailForm.elements.fullname.value, emailForm.elements.email.value, emailForm.elements.message.value, emailForm.elements.recipientId.value)
    //http://stage.realtimeexpress.com/Dashboard/Api/CombinedGroup/Email

    axios.post(DashboardUrl.concat('Api/CombinedGroup/Email'), {
        name: emailForm.elements.fullname.value,
        emailAddress: emailForm.elements.email.value,
        message: emailForm.elements.message.value,
        recipient: emailForm.elements.recipientId.value
    })
        .then(res => {
            if (res.data.success === "False") { throw res.data.error; }
            loadingComplete(emailForm);
            emailForm.classList.add("d-none");
            emailModal.querySelector(".complete").classList.remove("d-none");
        })
        .catch(e => {
            const div = document.createElement('div');
            div.classList.add("alert", "alert-danger", "text-center");
            div.innerText = e;
            agentLoginForm.prepend(div);
            loadingComplete(agentLoginForm);
            console.log(e)
        })


})

//Hide navbar sub menus on mobile view. Show only on click
let mq_mobile = window.matchMedia("(max-width: 991.98px)");
if (mq_mobile.matches) {
    const menuItems = document.querySelectorAll('nav a.nav-link');
    for (let item of menuItems) {        
        const subMenu = document.querySelector('nav .dropdown-menu[data-submenu=' + item.id + ']')
        if (!subMenu) { continue; }

        subMenu.setAttribute('style', 'display: none !important;')
        item.classList.remove('dropdown-toggle');
        item.addEventListener('click', toggleMobileMenu)
    }

}
mq_mobile.addEventListener("change", function (e) {
    const menuItems = document.querySelectorAll('nav a.nav-link');

    if (e.matches) {
        for (let item of menuItems) {
            const subMenu = document.querySelector('nav .dropdown-menu[data-submenu=' + item.id + ']')
            if (!subMenu) { continue; }

            subMenu.setAttribute('style', 'display: none !important;')
            item.classList.remove('dropdown-toggle');
            item.addEventListener('click', toggleMobileMenu)
        }
    } else {
        for (let item of menuItems) {
            const subMenu = document.querySelector('nav .dropdown-menu[data-submenu=' + item.id + ']')
            if (!subMenu) { continue; }

            subMenu.removeAttribute('style')
            item.removeEventListener('click', toggleMobileMenu);            
        };
    }
})
function toggleMobileMenu() {
    const subMenu = document.querySelector('nav .dropdown-menu[data-submenu=' + this.id + ']')

    if (subMenu.hasAttribute('style')) {
        subMenu.removeAttribute('style')
    } else {
        subMenu.setAttribute('style', 'display: none !important;')
    }
}